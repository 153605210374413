import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import PolicyContent from '../../components/PolicyContent';
import WrapperPage from '../../components/WrapperPage';
import WrapperSection from '../../components/WrapperSection';
import PageImage from '../../components/PageImage';

const PrivatePolicy = () => (
  <WrapperPage id="policy">
    <PageImage>
      <StaticImage
        src="../../assets/images/hands.jpg"
        alt="Polityka prywatności"
        style={{ width: '100%', height: '100%', opacity: 0.2 }}
        layout="fullWidth"
        objectPosition="50% 75%"
        quality={85}
        imgStyle={{
          filter: 'grayscale()',
        }}
      />
    </PageImage>
    <WrapperSection>
      <PolicyContent />
    </WrapperSection>
  </WrapperPage>
);

export default PrivatePolicy;
