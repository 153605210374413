import React, { useEffect } from 'react';
import gsap, { Expo } from 'gsap';

import * as S from './styled';

const PageImage = ({ children }) => {
  useEffect(() => {
    const tl = gsap.timeline({
      paused: true,
      defaults: { duration: 0.5, ease: Expo.easeOut },
    });
    gsap.set('.image-contianer', {
      filter: 'grayscale(100%)',
    });
    tl.from('.image-contianer', {
      scale: 0.8,
      alpha: 0,
      duration: 2.5,
      delay: 0.8,
    }).play();
  }, []);
  return (
    <S.ImageContainer className="image-contianer">
      {children}
    </S.ImageContainer>
  );
};

export default PageImage;
