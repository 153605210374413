import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import PrivatePolicy from '../../templates/PrivatePolicy';

const PolicyIndexPage = () => (
  <Layout>
    <PrivatePolicy />
  </Layout>
);

export default PolicyIndexPage;

export const Head = () => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  });
  return (
    <SEO title={`${t('policy')} - Giedeé Informatyka`}>
      <meta name="robots" content="noindex" />
    </SEO>
  );
};
