import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow: hidden;
  position: relative;
  z-index: 0;
  ${media.greaterThan('medium')`
  padding: 20px 40px;
  `}
  ${media.greaterThan('large')`
    padding: 40px;
  `}
`;
