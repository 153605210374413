import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  margin-top: 200px;
  ${media.greaterThan('medium')`
    padding:20px 50px;
  `}
`;
