import React from 'react';
// import { useTranslation } from 'react-i18next';

import * as S from './styled';

const WrapperPage = ({ children, id }) => (
  // const { t } = useTranslation('translation', {
  //   useSuspense: false,
  // });
  <S.Wrapper id={id}>{children}</S.Wrapper>
);
export default WrapperPage;
