import { Link } from 'gatsby';
import React from 'react';

import { useTranslation } from 'react-i18next';

import TitlePage from '../TitlePage';

import * as S from './styled';

const PolicyContent = () => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  });

  return (
    <S.Wrapper id="policy">
      <S.Container>
        <TitlePage text={t('policy')} subtitle={t('rodo')} />
        <S.Paragraph>
          Polityka prywatności strony internetowej giedee.pl
          <ol>
            <li>
              Informacje ogólne Niniejsza Polityka Prywatności określa
              zasady przetwarzania i ochrony danych osobowych
              przekazanych przez Użytkowników w związku z
              pozostawieniem danych w formularzu kontaktowym w
              Serwisie.
            </li>
            <li>
              Administratorem danych osobowych zawartych w serwisie
              jest Grzegorz Domaradzki
            </li>
            <li>
              W trosce o bezpieczeństwo powierzonych nam danych
              opracowaliśmy wewnętrzne procedury i zalecenia, które
              mają zapobiec udostępnieniu danych osobom
              nieupoważnionym. Kontrolujemy ich wykonywanie i stale
              sprawdzamy ich zgodność z odpowiednimi aktami prawnymi -
              ustawą o ochronie danych osobowych, ustawą o świadczeniu
              usług drogą elektroniczną, a także wszelkiego rodzaju
              aktach wykonawczych i aktach prawa wspólnotowego.
            </li>
            <li>
              Dane Osobowe przetwarzane są na podstawie zgody
              wyrażanej przez Użytkownika oraz w przypadkach, w
              których przepisy prawa upoważniają Administratora do
              przetwarzania danych osobowych na podstawie przepisów
              prawa lub w celu realizacji zawartej pomiędzy stronami
              umowy.
            </li>
            <li>
              Serwis realizuje funkcje pozyskiwania informacji o
              użytkownikach i ich zachowaniach w następujący sposób:
              <ul>
                <li>
                  poprzez dobrowolnie wprowadzone w formularzach
                  informacje
                </li>
                <li>
                  poprzez gromadzenie plików “cookies” [patrz polityka
                  plików “cookies”].
                </li>
              </ul>
            </li>
            <li>
              Serwis zbiera informacje dobrowolnie podane przez
              użytkownika.
            </li>
            <li>
              Dane podane w formularzu są przetwarzane w celu
              wynikającym z funkcji konkretnego formularza np. w celu
              dokonania procesu obsługi kontaktu informacyjnego
            </li>
            <li>
              Dane osobowe pozostawione w serwisie nie zostaną
              sprzedane ani udostępnione osobom trzecim, zgodnie z
              przepisami Ustawy o ochronie danych osobowych.
            </li>
            <li>
              Do danych zawartych w formularzu przysługuje wgląd
              osobie fizycznej, która je tam umieściła. Osoba ta ma
              również praw do modyfikacji i zaprzestania przetwarzania
              swoich danych w dowolnym momencie.
            </li>
            <li>
              Zastrzegamy sobie prawo do zmiany w polityce ochrony
              prywatności serwisu, na które może wpłynąć rozwój
              technologii internetowej, ewentualne zmiany prawa w
              zakresie ochrony danych osobowych oraz rozwój naszego
              serwisu internetowego. O wszelkich zmianach będziemy
              informować w sposób widoczny i zrozumiały.
            </li>
            <li>
              W Serwisie mogą pojawiać się linki do innych stron
              internetowych. Takie strony internetowe działają
              niezależnie od Serwisu i nie są w żaden sposób
              nadzorowane przez serwis paletypruszczgdanski.pl. Strony
              te mogą posiadać własne polityki dotyczące prywatności
              oraz regulaminy, z którymi zalecamy się zapoznać. W
              razie wątpliwości co któregokolwiek z zapisów niniejszej
              polityki prywatności jesteśmy do dyspozycji - nasze dane
              znaleźć można w zakładce -{' '}
              <Link to="/#contact">KONTAKT</Link>.
            </li>
          </ol>
        </S.Paragraph>
      </S.Container>
    </S.Wrapper>
  );
};

export default PolicyContent;
