import React, { useEffect, useRef } from 'react';
import gsap, { Expo, Power1 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import * as S from './styled';

const TitlePage = ({ text, subtitle }) => {
  gsap.registerPlugin(ScrollTrigger);
  let svgPath = useRef(null);
  let svgTag = useRef(null);
  let box = useRef(null);
  let mask = useRef(null);
  let descrip = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({
      paused: true,
      defaults: { duration: 0.5, ease: Power1.easeOut },
      scrollTrigger: {
        trigger: box,
        start: '-=200 bottom-=200px',
        end: '+=500',
        // markers: true,
      },
    });

    tl.to(svgTag, { delay: 0.5, opacity: 1 })
      .to(svgPath, {
        strokeDashoffset: 0,
        duration: 2,
      })
      .to(
        mask,
        {
          clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
          y: 0,
          stagger: 0.5,
          duration: 1,
          ease: Expo.easeOut,
        },
        '-=2.5'
      )
      .to(
        descrip,
        {
          clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
          y: 0,
          // stagger: 0.5,
          duration: 1,
          ease: Expo.easeOut,
        },
        '-=2'
      )
      .to(svgPath, { strokeDasharray: 'unset' });
  }, []);
  return (
    <S.Container
      ref={(el) => {
        box = el;
      }}
    >
      <S.Title>
        <S.TitleElement
          ref={(el) => {
            mask = el;
          }}
        >
          {text}
        </S.TitleElement>

        <S.ImageContainer>
          <svg
            width="93"
            height="108"
            viewBox="0 0 93 108"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ref={(el) => {
              svgTag = el;
            }}
          >
            <path
              id="path"
              d="M1.5 1.5H91.5V106.5H1.5V54V1.5Z"
              stroke="#393F4D"
              strokeWidth="3"
              strokeDasharray="390"
              strokeDashoffset="390"
              fillOpacity="0"
              ref={(el) => {
                svgPath = el;
              }}
            />
          </svg>
        </S.ImageContainer>
      </S.Title>
      <S.Description
        ref={(el) => {
          descrip = el;
        }}
      >
        {subtitle}
      </S.Description>
    </S.Container>
  );
};

export default TitlePage;
