import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  z-index: 0;
  overflow: hidden;
  user-select: none;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 10px;
  ${media.greaterThan('large')`
    max-width:1170px;

  `}
`;

export const Paragraph = styled.div`
  font-size: 14px;
  color: var(--primary-color);
  ol {
    list-style-type: decimal;
    padding-left: 10px;
  }
  ul {
    margin-left: 25px;
    list-style-type: disc;
  }
  li {
    margin-bottom: 15px;
  }
`;
